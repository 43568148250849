<script setup lang="ts">
const props = defineProps({
  src: { type: String as PropType<string | undefined>, required: false, default: undefined },
  alt: { type: String as PropType<string | undefined>, required: false, default: undefined },
  width: { type: Number as PropType<number | undefined>, required: false, default: undefined },
  height: { type: Number as PropType<number | undefined>, required: false, default: undefined },
  h: { type: String as PropType<string | undefined>, required: false, default: undefined },
  maxWidth: { type: String as PropType<string | undefined>, required: false, default: undefined },
  rounded: { type: Boolean, required: false, default: true },
  dropShadow: { type: Boolean, required: false, default: true },
  fullHeight: { type: Boolean, required: false, default: false },
  lazyload: { type: Boolean, required: false, default: true },
})

const isSvg = computed(() => {
  return props.src && getCleanUrl(props.src) && getCleanUrl(props.src).endsWith('.svg')
})

const imgSrc: Ref<string> = computed(() => {
  let height = ''
  if (props.h) {
    height = '&h=' + props.h
  }

  if (isSvg.value && props.src !== undefined) {
    return props.src ? getCleanUrl(props.src) : ''
  }

  if (props.maxWidth && props.src) {
    if (!props.src.includes('&h=') && !props.src.includes('&w=')) {
      return props.src + '&w=' + props.maxWidth
    }
  }

  if (props.src) {
    return props.src + height
  }

  return ''
})
</script>

<template v-if="src !== ''">
  <img
    :data-rounded="props.rounded"
    :data-dropshadow="props.dropShadow"
    :data-fullheight="props.fullHeight"
    :loading="props.lazyload ? 'lazy' : 'eager'"
    class="rounded-3xl overflow-hidden drop-shadow-2xl data-[fullheight=true]:h-full data-[fullheight=true]:object-cover data-[rounded=false]:rounded-none data-[dropshadow=false]:drop-shadow-none pointer-events-none"
    :src="imgSrc"
    :alt="props.alt"
    :width="props.width"
    :height="props.height"
  />
</template>
